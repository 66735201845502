/*!
 *    Personal website of Sefa Eyeoglu
 *    Copyright (C) 2018-2022  Sefa Eyeoglu <contact@scrumplex.net>
 *
 *    This program is free software: you can redistribute it and/or modify
 *    it under the terms of the GNU Affero General Public License as published by
 *    the Free Software Foundation, either version 3 of the License, or
 *    (at your option) any later version.
 *
 *    This program is distributed in the hope that it will be useful,
 *    but WITHOUT ANY WARRANTY; without even the implied warranty of
 *    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *    GNU Affero General Public License for more details.
 *
 *    You should have received a copy of the GNU Affero General Public License
 *    along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import { tsParticles } from "tsparticles-engine";
import { loadAngleUpdater } from "tsparticles-updater-angle";
import { loadBaseMover } from "tsparticles-move-base";
import { loadCircleShape } from "tsparticles-shape-circle";
import { loadColorUpdater } from "tsparticles-updater-color";

import { loadOpacityUpdater } from "tsparticles-updater-opacity";
import { loadSizeUpdater } from "tsparticles-updater-size";

import config from "./tsParticles-config.json";
import ready from "./_utils";

const { matches } = matchMedia("(min-width: 768px)");

async function loadParticles() {
    await loadAngleUpdater(tsParticles);
    await loadBaseMover(tsParticles);
    await loadCircleShape(tsParticles);
    await loadColorUpdater(tsParticles);
    await loadOpacityUpdater(tsParticles);
    await loadSizeUpdater(tsParticles);
    tsParticles
        .load("particles-js", config)
        .then()
        .catch((e) => {
            console.error("tsparticles failed to load :(");
            console.error(e);
        });
}

if (matches) {
    ready().then(() => {
        // tsParticles causes heavy lag while loading, lets delay it, so that all animations are smooth at least
        setTimeout(loadParticles, 500);
    });
}
